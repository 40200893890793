.App {
  text-align: center;
}



/* Text Animations */
  #Dawn {
    opacity: 0;
    transition:  all .5s ease;
    animation: SloganAnimation ease 1.5s forwards; 
    animation-delay: 500ms;
  }

  #Day {
    opacity: 0;
    transition:  all .5s ease;
    animation: SloganAnimation ease 1.5s forwards; 
    animation-delay: 1000ms;
    
  }

  #Life {
    opacity: 0;
    transition:  all .5s ease;
    animation: SloganAnimation ease 1.5s forwards; 
    
    animation-delay: 1500ms;
  }

  #Bar {
    opacity: 0;
    transition:  all .5s ease;
    animation: BarAnimation ease 1.5s forwards;
    animation-delay: 1.5s;
    
  }

  #Sub{
    opacity: 0;
    transition: all .5 ease;
    animation: FadeIn ease 1.5s forwards;
    animation-delay: 2.5s
  }

  #Desc {
    opacity: 0;
    transition: all .5s ease;
    animation: FadeDrop ease 1.5s forwards;
    animation-delay: 2.6s;
  }

/* Style Classes */

.App-header {
  background-color: #171f22;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ImageTitle{
  flex-basis: 30%;
}

.Text-White {
  color:white;
}

.Green {
  background-color: #50b962;
  box-shadow: 0px 4px 4px 5px rgba(0, 0, 0, 0.25);
}

.Grey4 {
  background-color:#1c2529;
}

.Subtitle {
  font-size: medium;
}


.ScreenStretch {
  width: 100%;
}

.PadSmall {
  padding: 3%;
  align-items: flex-start;
}

.PadTop {
  padding-top: 1%;
}

.PadBottom {
  padding-bottom: 10%;
}

.Header{
  top: 0;
  position: absolute;
}

.Footer {
  bottom: 0;
  position: absolute;
  box-shadow: 0px 4px 4px 10px rgba(0, 0, 0, 0.25);
}

/* Keyframe animations */

@keyframes SloganAnimation {
  from {
    opacity: 0%;
    translate: -px;
  }

  to{
    opacity: 100%;
    translate: 0px;
  }
}

@keyframes BarAnimation {
  from {
    opacity: 0%;
    transform: scale(0, 1)
  }

  to {
    opacity: 100%;
    transform: scale(1, 1)
  }
}

@keyframes FadeIn {
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes FadeDrop {
  from {
    opacity: 0;
    transform: translate(0, -15px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }

}


